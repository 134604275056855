import { Box, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import { AddressIcon } from '../../Icons/AddressIcon';
import { BookIcon } from '../../Icons/BookIcon';
import { ChartSanki } from '../../Icons/ChartSanki';
import { HomeIcon } from '../../Icons/HomeIcon';
import { Logout } from '../../Icons/LogoutIcon';
import { Portfolio } from '../../Icons/PortFolio';
import { SettingsWheel } from '../../Icons/SettingWheel';
import { WalletNew } from '../../Icons/WalletNew';
import { OverlayProps, SidebarOption } from '../../interfaces';
import { loadingStore, useLoginStore } from '../../store';

import Header from './Header';
import Sidebar from './Sidebar';

function Overlay({ children }: OverlayProps) {
  const location = useLocation();
  const [activeView, setActiveView] = useState('dashboard');
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const userData = useLoginStore((state: any) => state.userData);
  const stepNumber = loadingStore((state: any) => state.stepNumber);
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);

  useEffect(() => {
    const activeSidebarOption = sidebarOptions.find(
      (sidebarOption: SidebarOption) => sidebarOption.href === location.pathname,
    );

    activeSidebarOption ? setActiveView(activeSidebarOption.key) : setActiveView('');
  }, [location.pathname]);

  const sidebarOptions: SidebarOption[] = [
    {
      href: '/',
      key: 'Home',
      text: 'Home',
      sideBarIcon: <HomeIcon width="24px!important" height="24px !important" fill="currentColor" boxSize={[4]} />,
    },
    {
      href: '/tokenHolders',
      key: 'tokenHolders',
      text: 'Token Holders',
      sideBarIcon: <Portfolio fill="currentcolor" boxSize={[4]} />,
    },

    {
      href: '/transactions',
      key: 'transactions',
      text: 'Transactions',
      sideBarIcon: <WalletNew fill="currentColor" boxSize={[4]} />,
    },

    {
      href: '/SankeyChart',
      key: 'SankeyChart',
      text: 'Sankey Charts',
      sideBarIcon: <ChartSanki fill="currentcolor" boxSize={4} />,
    },

    {
      href: '/BookDashboard',
      key: 'BookDashboard',
      text: 'Book Dashboard',
      sideBarIcon: <AddressIcon fill="currentColor" boxSize={[4]} />,
    },

    {
      href: '/addressBook',
      key: 'addressBook',
      text: 'Address Book',
      sideBarIcon: <BookIcon fill="currentColor" boxSize={[4]} />,
    },

    {
      href: '/settings',
      key: 'settings',
      text: 'Settings',
      sideBarIcon: <SettingsWheel fill="currentColor" boxSize={[4]} />,
    },
    {
      href: '/',
      key: 'logout',
      text: 'Logout',
      sideBarIcon: <Logout fill="currentColor" boxSize={[4]} />,
    },
  ];

  useEffect(() => {
    axios
      .get('/api/user/current/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      })
      .then((response) => {
        setLoginDetails(response?.data?.data);
      })
      .catch((err: any) => {
        console.log('Error fetching user data:', err);
      });
  }, [stepNumber]);

  const showOverlay = userData?.blockchain_network?.chain_id !== 1 && activeView === 'dashboard';

  return (
    <Box w="100%" h="100vh" display="flex" flexDirection="column">
      {!userData?.token_address || userData?.token_address === '' || (stepNumber >= 2 && stepNumber <= 8) ? (
        <Box
          w="100%"
          h="100vh"
          display="flex"
          flexDirection="column"
          backgroundColor="var(--Neutrals-8, #353535)"
        ></Box>
      ) : (
        <Box className="overlay-wrap" display="flex" flex="1">
          <Sidebar
            sideBarOpen={sideBarOpen}
            activeView={activeView}
            sidebarOptions={sidebarOptions}
            setActiveView={setActiveView}
            setSideBarOpen={setSideBarOpen}
          />

          <Box
            className="content-wrap"
            flex="1"
            width={['100%']}
            height={['calc(100vh - 0px)', 'calc(100vh - 0px)', 'calc(100vh - 0px)']}
            display="flex"
            flexDirection="column"
            padding={['0px', '0px', '0px', '0px', '0px']}
            margin={0}
            overflowY="auto"
            position="relative"
          >
            <Header
              sideBarOpen={sideBarOpen}
              activeView={activeView}
              sidebarOptions={sidebarOptions}
              setActiveView={setActiveView}
              setSideBarOpen={setSideBarOpen}
            />
            <Box position="relative" flex="1" overflowY="auto">
              {children}
              {showOverlay && (
                <Box
                  position="sticky"
                  top="0"
                  left="0"
                  right="0"
                  bottom="0"
                  height="100%"
                  display="flex"
                  alignItems="center"
                  backgroundColor="rgba(255, 255, 255, 0.7)"
                  backdropFilter="blur(10px)"
                  justifyContent="center"
                  zIndex="1000"
                >
                  <Text fontSize="3xl" fontWeight="bold" color="gray.800" textShadow="0 1px 2px rgba(0, 0, 0, 0.1)">
                    Coming Soon hello
                  </Text>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Overlay;
