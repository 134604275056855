import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import Frame1 from '../assets/images/svgs/Frame1.svg';
import Frame2 from '../assets/images/svgs/Frame2.svg';
import Frame3 from '../assets/images/svgs/Frame3.svg';
import Frame4 from '../assets/images/svgs/Frame4.svg';
import Frame6 from '../assets/images/svgs/Frame6.svg';
import RefreshIcon from '../assets/images/svgs/RefreshIcon.svg';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import Text from '../components/common/Text';
import TokenAddressModal from '../components/modals/TokenAddressModal';

interface NotificationCount {
  total_unread: number;
  by_category: Record<string, number>;
}

const Settings = () => {
  const [selected, setSelected] = useState('update');
  const [isTokenAddressModalOpen, setIsTokenAddressModalOpen] = useState(false);
  const [pairAddress, setPairAddress] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [loadingStates, setLoadingStates] = useState({
    inOutFlow: false,
    userTransactions: false,
    tokenHolders: false,
  });
  const [lastUpdateTimes, setLastUpdateTimes] = useState({
    inOutFlow: null,
    userTransactions: null,
    tokenHolders: null,
    tokenAddress: null,
  });
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    fetchLastUpdateTimes();
  }, []);

  const fetchLastUpdateTimes = async () => {
    try {
      const response = await axios.get('api/user/current/');
      setLastUpdateTimes({
        inOutFlow: response.data.data.last_inflow_outflow_update,
        userTransactions: response.data.data.last_token_transactions_update,
        tokenHolders: response.data.data.last_tokenholders_update,
        tokenAddress: response.data.data.token_address,
      });
    } catch (error) {
      console.error('Failed to fetch last update times:', error);
    }
  };

  const handleUpdate = async (endpoint: string, stateKey: keyof typeof loadingStates) => {
    if (loadingStates[stateKey]) return;

    setLoadingStates((prev) => ({ ...prev, [stateKey]: true }));
    setIsUpdating(true);

    try {
      await axios.post(
        `/api/user/${endpoint}`,
        {},
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      let attempts = 0;
      const maxAttempts = 10;

      const pollData = async () => {
        if (attempts >= maxAttempts) {
          if (pollInterval) clearInterval(pollInterval);
          setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
          setIsUpdating(false);
          toast({
            title: 'Update timed out',
            description: 'Please try again',
            status: 'warning',
            isClosable: true,
          });
          return;
        }

        try {
          const response = await axios.get('api/user/current/');
          if (response.status === 200) {
            const keyMapping = {
              inOutFlow: 'last_inflow_outflow_update',
              userTransactions: 'last_token_transactions_update',
              tokenHolders: 'last_tokenholders_update',
            };

            const newTimestamp = response.data.data[keyMapping[stateKey]];

            if (newTimestamp) {
              setLastUpdateTimes((prev) => ({
                ...prev,
                [stateKey]: newTimestamp,
              }));
            }

            clearInterval(pollInterval);
            setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
            setIsUpdating(false);
            toast({
              title: 'Data updated successfully',
              status: 'success',
              duration: 3000,
              isClosable: true,
            });
          }
        } catch (error) {
          attempts++;
        }
      };

      const pollInterval = setInterval(pollData, 2000);

      // Add cleanup timeout
      setTimeout(() => {
        clearInterval(pollInterval);
        if (loadingStates[stateKey]) {
          setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
          setIsUpdating(false);
          toast({
            title: 'Update timed out',
            description: 'Please try again',
            status: 'warning',
            isClosable: true,
          });
        }
      }, 30000);
    } catch (error) {
      console.error('Error during update:', error);
      toast({
        title: 'Error updating data',
        status: 'error',
        isClosable: true,
      });
      setLoadingStates((prev) => ({ ...prev, [stateKey]: false }));
      setIsUpdating(false);
    }
  };

  const formatLastUpdateTime = (timestamp: string | null) => {
    if (!timestamp) return 'Never updated';
    const date = new Date(timestamp);
    return `Last updated - ${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
  };

  const handleTokenAddressSuccess = () => {
    setSelected('update');
  };

  const handleAddPairAddress = async () => {
    try {
      const response = await axios.patch(
        '/api/user/account/',
        { pair_address: pairAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Pair address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        setPairAddress('');
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update pair address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateItems = [
    {
      icon: Frame1,
      title: 'Update In-Flow & Out-Flow Transactions',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.inOutFlow),
      endpoint: 'trigger-update-user-inflow-outflow/',
      stateKey: 'inOutFlow',
    },
    {
      icon: Frame2,
      title: 'Update User Transactions',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.userTransactions),
      endpoint: 'trigger-update-user-token-transactions/',
      stateKey: 'userTransactions',
    },
    {
      icon: Frame3,
      title: 'Update Token Holders',
      lastUpdated: formatLastUpdateTime(lastUpdateTimes.tokenHolders),
      endpoint: 'trigger-update-user-tokenholders/',
      stateKey: 'tokenHolders',
    },
  ];

  const manageItems = [
    {
      icon: Frame4,
      title: 'Add your token address',
      actionButton: 'Add Address',
      onClick: () => setIsTokenAddressModalOpen(true),
    },

    {
      icon: Frame6,
      title: 'Manage your Inflow and Outflow Addresses',
      actionButton: 'Manage',
      onClick: () => navigate('/flowAddress'),
    },
  ];

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  const renderUpdateTable = () => {
    return (
      <>
        {updateItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            padding={['0px 0px 20px 0px', '0px 0px 20px 0px', '0px 0px 20px 0px', '0px 12px 20px 12px']}
            alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
            alignSelf="stretch"
            gap={'12px'}
            flexDirection={['column', 'column', 'column', 'row']}
            borderBottom={index === updateItems.length - 1 ? 'none' : '1px solid rgba(246, 246, 246, 0.9)'}
          >
            <Box
              display="flex"
              flexDirection={['column', 'column', 'column', 'row']}
              paddingRight="24px"
              gap={'12px'}
              justifyContent="space-between"
              alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
              flex="1 0 0"
            >
              <Box display="flex" alignItems="center" gap="10px">
                <Box
                  display="flex"
                  padding="10px"
                  alignItems="center"
                  gap="10px"
                  borderRadius="100px"
                  background="var(--Neutrals-2, #F6F6F6)"
                >
                  <Image src={item.icon} alt={item.title} width="24px" height="24px" />
                </Box>
                <Text fs="14px" fw={500} lineHeight={'22px'}>
                  {item.title}
                </Text>
              </Box>
              <Text
                c="var(--Text-Primary-description, #909090)"
                fs="14px"
                fw={500}
                lineHeight={'22px'}
                textAlign={'left'}
              >
                {item.lastUpdated}
              </Text>
            </Box>
            <Flex
              padding="6px 12px 6px 6px"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap="8px"
              borderRadius="22px"
              background="#000"
              cursor="pointer"
              position="relative"
              onClick={() => handleUpdate(item.endpoint, item.stateKey as keyof typeof loadingStates)}
              opacity={loadingStates[item.stateKey as keyof typeof loadingStates] ? 0.7 : 1}
            >
              <Image src={RefreshIcon} alt="refresh" />
              <Text
                c="var(--Button-colorTextLightSolid, #FFF)"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="22px"
              >
                {loadingStates[item.stateKey as keyof typeof loadingStates] ? 'Updating...' : 'Refresh'}
              </Text>
            </Flex>
          </Box>
        ))}
      </>
    );
  };

  const renderManageTable = () => {
    return (
      <>
        {manageItems.map((item, index) => (
          <Box
            key={index}
            display="flex"
            padding={['0px 0px 20px 0px', '0px 0px 20px 0px', '0px 0px 20px 0px', '0px 12px 20px 12px']}
            alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
            alignSelf="stretch"
            borderBottom={index === manageItems.length - 1 ? 'none' : '1px solid rgba(246, 246, 246, 0.9)'}
          >
            <Box
              display="flex"
              paddingRight={['0px', '0px', '0px', '24px']}
              flexDirection={['column', 'column', 'column', 'row']}
              justifyContent="space-between"
              alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
              flex="1 0 0"
              gap={'12px'}
            >
              <Box display="flex" alignItems="center" gap="10px">
                <Box
                  display="flex"
                  padding="10px"
                  alignItems="center"
                  gap="10px"
                  borderRadius="100px"
                  background="var(--Neutrals-2, #F6F6F6)"
                >
                  <Image src={item.icon} width="24px" height="24px" />
                </Box>
                <Text fs="14px" fw={500} lineHeight={'22px'} textAlign={'left'}>
                  {item.title}
                </Text>
              </Box>

              <Box
                display="flex"
                padding="6px var(--Button-paddingContentHorizontal, 16px)"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="8px"
                borderRadius="16px"
                border="1px solid rgba(0, 0, 0, 0.15)"
                background="var(--Button-colorBgContainer, #FFF)"
                cursor="pointer"
                onClick={item.onClick}
              >
                <Text c="rgba(0, 0, 0, 0.88)" fs="14px" fw="500" lineHeight="22px">
                  {item.actionButton}
                </Text>
              </Box>
            </Box>
          </Box>
        ))}
      </>
    );
  };

  return (
    <Box
      display="flex"
      padding={['24px 12px', '24px 12px', '24px 12px', '14px 24px 40px 24px']}
      flexDirection="column"
      alignItems="flex-start"
      gap={['24px', '24px', '24px', '56px']}
      flex="1 0 0"
      alignSelf="stretch"
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
    >
      {/* Header */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text fs={['24px', '24px', '24px', '36px']} lineHeight={'28px'} textAlign={'left'}>
          Settings
        </Text>
        <Text fs="14px" lineHeight={'22px'} fw={400} textAlign={'left'}>
          Customize your experience. Manage updates for transactions, addresses, and token holders with ease.
        </Text>
      </Box>

      {/* Main Content */}
      <Box
        display="flex"
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius={['0px', '0px', '0px', '36px']}
        border={['none', 'none', 'none', '1px solid rgba(255, 255, 255, 0.10)']}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        background="var(--Neutrals-1, #FFF)"
        boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
      >
        {/* Switcher */}

        {isMobile ? (
          <Flex width={'100%'} justifyContent={'space-between'} gap={'12px'} flexDirection={'column'}>
            <InputGroup
              width={['100%', '100%', '100%', 'fit-content']}
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                placeholder="Find a Setting"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
            <Box
              display="flex"
              padding="5px"
              alignItems="center"
              gap="10px"
              width={'fit-content'}
              borderRadius="14px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Box
                display="flex"
                padding="6px 12px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="10px"
                background={selected === 'update' ? '#FFF' : 'transparent'}
                boxShadow={selected === 'update' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
                cursor="pointer"
                onClick={() => setSelected('update')}
              >
                <Text
                  c={
                    selected === 'update'
                      ? 'var(--Text-Primary-default, #000)'
                      : 'var(--Text-Primary-description, #909090)'
                  }
                  fw={500}
                  fs="14px"
                  lineHeight={'22px'}
                >
                  Update data
                </Text>
              </Box>
              <Box
                display="flex"
                padding="6px 12px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="10px"
                background={selected === 'manage' ? '#FFF' : 'transparent'}
                boxShadow={selected === 'manage' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
                cursor="pointer"
                onClick={() => setSelected('manage')}
              >
                <Text
                  c={
                    selected === 'manage'
                      ? 'var(--Text-Primary-default, #000)'
                      : 'var(--Text-Primary-description, #909090)'
                  }
                  fw={500}
                  fs="14px"
                  lineHeight={'22px'}
                >
                  Manage addresses
                </Text>
              </Box>
            </Box>
          </Flex>
        ) : (
          <Flex width={'100%'} justifyContent={'space-between'}>
            <Box
              display="flex"
              padding="5px"
              alignItems="center"
              gap="10px"
              borderRadius="14px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Box
                display="flex"
                padding="6px 12px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="10px"
                background={selected === 'update' ? '#FFF' : 'transparent'}
                boxShadow={selected === 'update' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
                cursor="pointer"
                onClick={() => setSelected('update')}
              >
                <Text
                  c={
                    selected === 'update'
                      ? 'var(--Text-Primary-default, #000)'
                      : 'var(--Text-Primary-description, #909090)'
                  }
                  fw={500}
                  fs="14px"
                  lineHeight={'22px'}
                >
                  Update data
                </Text>
              </Box>
              <Box
                display="flex"
                padding="6px 12px"
                justifyContent="center"
                alignItems="center"
                gap="10px"
                borderRadius="10px"
                background={selected === 'manage' ? '#FFF' : 'transparent'}
                boxShadow={selected === 'manage' ? '0px 1px 3px 0px rgba(0, 0, 0, 0.19)' : 'none'}
                cursor="pointer"
                onClick={() => setSelected('manage')}
              >
                <Text
                  c={
                    selected === 'manage'
                      ? 'var(--Text-Primary-default, #000)'
                      : 'var(--Text-Primary-description, #909090)'
                  }
                  fw={500}
                  fs="14px"
                  lineHeight={'22px'}
                >
                  Manage addresses
                </Text>
              </Box>
            </Box>
            <InputGroup
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                placeholder="Find a Setting"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Flex>
        )}

        {/* Conditional Table Rendering */}
        {selected === 'update' ? renderUpdateTable() : renderManageTable()}
      </Box>

      {/* Modals */}
      <TokenAddressModal
        isShowModal={isTokenAddressModalOpen}
        setIsShowModal={setIsTokenAddressModalOpen}
        onSuccess={handleTokenAddressSuccess}
        initialTokenAddress={lastUpdateTimes.tokenAddress || ''}
      />

      <Modal
        isOpen={isUpdating}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => {
          setIsUpdating(false);
          setLoadingStates({
            inOutFlow: false,
            userTransactions: false,
            tokenHolders: false,
          });
        }}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color={'#1f1f1f'}>
                Fetching Latest Data
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color={'#1f1f1f'} mt={4}>
                This may take some time, please be patient
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Settings;
