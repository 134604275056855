import { Box, Button, Tooltip } from '@chakra-ui/react';
import React from 'react';
import { SidebarItemProps } from '../../interfaces';
import { useLoginStore } from '../../store';

function SidebarItem({
  sideBarItemKey,
  sideBarIcon,
  text,
  sideBarOpen,
  active,
  last,
  setActiveView,
}: SidebarItemProps) {
  const setLoginDetails = useLoginStore((state: any) => state.setLoginDetails);
  const setFromLoadingPage = useLoginStore((state: any) => state.setFromLoadingPage);

  const onLogoutClick = () => {
    if (sideBarItemKey === 'logout') {
      setLoginDetails({});
      setFromLoadingPage(false);
    }
  };
  return (
    <Box width={['100%']} height={['50px']} display="flex" alignItems="center" onClick={onLogoutClick}>
      {sideBarOpen ? (
        <Button
          leftIcon={sideBarIcon}
          width="100%"
          variant="ghost"
          padding={'16px'}
          borderRadius={'16px'}
          background={
            sideBarItemKey === 'logout'
              ? 'rgba(109, 109, 109, 0.15)'
              : last
              ? 'Transparent'
              : active
              ? '#37485D'
              : 'Transparent'
          }
          color={sideBarItemKey === 'logout' ? '#FF4D4F' : active ? '#4096FF' : '#909090'}
          _hover={{
            bg: sideBarItemKey === 'logout' ? '#FF4D4F' : active ? '#37485D' : 'rgba(109, 109, 109, 0.35)',
            color: sideBarItemKey === 'logout' ? '#FFFFFF' : active ? '#4096FF' : '#909090',
          }}
          _active={{
            bg: '#37485D',
            color: '#4096FF',
          }}
          textTransform="capitalize"
          fontSize={['14px']}
          onClick={() => !last && setActiveView(sideBarItemKey)}
          fontWeight={500}
          fontStyle="normal"
          justifyContent="flex-start"
          transition="0.2s all"
        >
          {text}
        </Button>
      ) : (
        <Tooltip
          label={text}
          placement="right"
          bg="white"
          color="black"
          fontSize="14px"
          padding="4px 8px"
          borderRadius="12px"
          background={'var(--Neutrals-1, #FFFFFF)'}
          boxShadow="0px 7px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 3px 16px 0px rgba(0, 0, 0, 0.08)"
          border={'1px solid var(--Neutrals-5, #E5E5E5)'}
        >
          <Box
            width={'50px'}
            height="50px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={() => !last && setActiveView(sideBarItemKey)}
            borderRadius={'16px'}
            padding={'16px'}
            background={
              sideBarItemKey === 'logout'
                ? 'rgba(109, 109, 109, 0.15)'
                : last
                ? 'rgba(109, 109, 109, 0.15)'
                : active
                ? '#37485D'
                : 'rgba(109, 109, 109, 0.15)'
            }
            color={sideBarItemKey === 'logout' ? '#FF4D4F' : active ? '#4096FF' : '#909090'}
            _hover={{
              bg: sideBarItemKey === 'logout' ? '#FF4D4F' : active ? '#37485D' : 'rgba(109, 109, 109, 0.35)',
              color: sideBarItemKey === 'logout' ? '#FFFFFF' : active ? '#4096FF' : '#909090',
            }}
            _active={{
              bg: '#37485D',
              color: '#4096FF',
            }}
            transition="0.2s all"
          >
            {sideBarIcon}
          </Box>
        </Tooltip>
      )}
    </Box>
  );
}

export default SidebarItem;
