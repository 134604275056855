import { Box, Image } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import errorIcon from '../../assets/images/svgs/errorIcon.svg';
import lock from '../../assets/images/svgs/lock.svg';
import Text from '../common/Text';

interface PassStep4Props {
  isSuccess?: boolean;
}

export const PassStep4: React.FC<PassStep4Props> = ({ isSuccess = true }) => {
  const navigate = useNavigate();

  const content = {
    success: {
      title: 'Password reset successful',
      description: '',
      icon: lock,
      iconBg: 'var(--green-1, #EFFBF5)',
      actionText: 'Click here to',
      actionLink: 'Login',
      actionColor: 'var(--green-5, #39CA7F)',
      onClick: () => navigate('/'),
    },
    error: {
      title: 'Reset request failed ',
      description: 'Please double check your email and try again',
      icon: errorIcon,
      iconBg: 'var(--orange-1, #FFF7E6)',
      actionText: 'Click here to',
      actionLink: ' Try agin',
      actionColor: 'var(--red-5, #FF4D4F)',
      onClick: () => navigate('/reset-password'),
    },
  };

  const currentContent = isSuccess ? content.success : content.error;

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
      width={'100%'}
      bg="var(--Neutrals-1, #FFF)"
    >
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        alignItems="center"
        gap="36px"
        borderRadius="16px"
      >
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs={'28px'}>{currentContent.title}</Text>
          <Text
            fs={'14px'}
            fw={500}
            lineHeight={'22px'}
            c="var(--Text-Primary-description, #909090)"
            textAlign={'center'}
          >
            {currentContent.description}
          </Text>
        </Box>
        <Box
          width={'64px'}
          height={'64px'}
          display="flex"
          borderRadius="64px"
          bg={currentContent.iconBg}
          justifyContent={'center'}
          alignItems="center"
        >
          <Image src={currentContent.icon} alt={isSuccess ? 'success' : 'error'} />
        </Box>
        <Text
          fs={'14px'}
          fw={500}
          lineHeight={'22px'}
          c="var(--Text-Primary-description, #909090)"
          textAlign={'center'}
          cursor="pointer"
          onClick={currentContent.onClick}
        >
          {currentContent.actionText}{' '}
          <span style={{ color: currentContent.actionColor }}>{currentContent.actionLink}</span>
        </Text>
      </Box>
    </Box>
  );
};

export default PassStep4;
