import { Box, Button, Image } from '@chakra-ui/react';
import { default as React, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import CoveyBlack from '../../assets/images/svgs/CoveyBlack.svg';
import CoveyLogoText from '../../assets/images/svgs/CoveyText.svg';
import Text from '../common/Text';
import { PasswordField } from '../logIn/loginForm/PasswordField';

const PassStep3 = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email;

  const handleResetPassword = async (): Promise<void> => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('/api/user/reset-password/', {
        email,
        new_password: password,
        confirm_password: confirmPassword,
      });

      if (response.status === 200) {
        navigate('/passstep-4');
      } else {
        setError(response.data.error || 'Failed to reset password');
      }
    } catch (error) {
      setError('Network error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent={'center'}
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
      width={'100%'}
      bg="var(--Neutrals-1, #FFF)"
    >
      <Box
        display="flex"
        padding={['0px 12px', '0px 24px', '0px']}
        width={['100%', '100%', 'fit-content', '410px']}
        flexDirection="column"
        alignItems="center"
        gap="36px"
        borderRadius="16px"
      >
        <Box display={'flex'} gap="10px" justifyContent={'center'} width={'100%'}>
          <Image src={CoveyBlack} width={'38.276px'} height={'31.406px'} />
          <Image src={CoveyLogoText} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center" gap="8px" alignSelf="stretch">
          <Text fs={'28px'}>Create new password</Text>
          <Text
            fs={'14px'}
            fw={500}
            lineHeight={'22px'}
            c="var(--Text-Primary-description, #909090)"
            textAlign={'center'}
          >
            A password reset link will be sent to your email address
          </Text>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap="24px" alignSelf="stretch">
          <Box display="flex" flexDirection="column" alignItems="flex-start" gap="20px" alignSelf="stretch">
            <PasswordField
              password={password}
              setPassword={setPassword}
              isRequired={true}
              label="New password"
              placeholder="Enter your new password"
            />
            <PasswordField
              password={confirmPassword}
              setPassword={setConfirmPassword}
              placeholder="Enter your new password again"
              isRequired={true}
              label="Confirm new password"
            />
          </Box>
          {error && (
            <Text color="red.500" fontSize="14px">
              {error}
            </Text>
          )}
          <Button
            display="flex"
            alignItems="center"
            gap="var(--Checkbox-marginXS, 8px)"
            borderRadius="16px"
            border="1px solid #2D8DFB"
            background="#2D8DFB"
            _hover={{ background: '#2D8DFB' }}
            color="var(--Button-colorTextLightSolid, #FFF)"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            width={'100%'}
            lineHeight="22px"
            onClick={handleResetPassword}
            isLoading={isLoading}
            isDisabled={!password || !confirmPassword}
          >
            <span>Set new password</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default PassStep3;
