import { Box } from '@chakra-ui/react';
import React from 'react';
import Text from './Text';

interface iProp {
  title: string;
  box: string;
  isActive: boolean;
  amount: string;
  address: string;
  selected: boolean;
  onClick: () => void;
}

const Block: React.FC<iProp> = ({ title, box, amount, isActive, address, onClick, selected }) => {
  return (
    <Box
      onClick={onClick}
      cursor="pointer"
      minWidth={'310px'}
      display="flex"
      padding="24px"
      flexDirection="column"
      alignItems="flex-start"
      gap="16px"
      borderRadius={['24px', '24px', '24px', '36px']}
      border={
        selected
          ? '1px solid var(--Text-action, #4096FF)'
          : [
              '1px solid rgba(0, 0, 0, 0.05)',
              '1px solid rgba(0, 0, 0, 0.05)',
              '1px solid rgba(0, 0, 0, 0.05)',
              '1px solid rgba(255, 255, 255, 0.10)',
            ]
      }
      background="var(--Neutrals-1, #FFF)"
      boxShadow={
        selected
          ? ' 0px 4px 17px 0px rgba(113, 147, 189, 0.40)'
          : ['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']
      }
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start" gap="12px" alignSelf="stretch">
        <Box display="flex" justifyContent="space-between" alignItems="flex-start" alignSelf="stretch">
          <Text c="var(--Text-Black, #000)" fs={'16px'} fontStyle={'normal'} fw={600} lineHeight={'28x'}>
            {title}
          </Text>
          <Text
            display={isActive ? 'flex' : 'none'}
            padding="4px 12px"
            alignItems="center"
            gap="6px"
            borderRadius="16px"
            background="var(--Neutrals-2, #F6F6F6)"
            c="var(--Text-Primary-description, #909090)"
            fs="14px"
            fontStyle="normal"
            fw={500}
            lineHeight="22px"
          >
            {box}
          </Text>
        </Box>

        <Text c="var(--Dark-Tertiary-tr20, #080808)" fs="42px" fontStyle="normal" fw={600} lineHeight="52px">
          {amount}
        </Text>
      </Box>
      <Text c="var(--Dark-Tertiary-tr70, #878787)" fs="12px" fontStyle="normal" fw={50} lineHeight="22px">
        {address}
      </Text>
    </Box>
  );
};

export default Block;
