import {
  Box,
  Image,
  Input,
  InputGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import ExternalLink from '../../src/assets/images/svgs/ExternalLink.svg';
import OrangeDollar from '../../src/assets/images/svgs/OrangeDollar.svg';
import RefreshIcon from '../../src/assets/images/svgs/Refresh.svg';
import SearchIcon from '../../src/assets/images/svgs/SearchIcon.svg';
import axios from '../api/axios';
import PriceChart from '../components/Charts/PriceChart';
import VolumeChart from '../components/Charts/VolumeChart';
import Text from '../components/common/Text';
import { ChartConfig, ExchangeData, HolderData, VolumeData } from '../types/Types';
import TablePagination from './TablePagination';

const ITEMS_PER_PAGE = 20;

function Home() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [exchangeData, setExchangeData] = useState<ExchangeData[]>([]);
  const [isLoadingHolders, setIsLoadingHolders] = useState(false);
  const [validDates, setValidDates] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [holderData, setHolderData] = useState<HolderData | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState('');
  const [exchangeType, setExchangeType] = useState('all');
  const [isLoading, setIsLoading] = useState(false);
  const [volumeData, setVolumeData] = useState<VolumeData | undefined>(undefined);
  const [hasError, setHasError] = useState(false);
  const toast = useToast();

  const fetchTokenData = async () => {
    setIsLoading(true);
    setHasError(false);
    try {
      const token = localStorage.getItem('access_token');

      if (!token) {
        toast({
          title: 'Authentication Error',
          description: 'Please login again',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setHasError(true);
        return;
      }

      const results = await Promise.allSettled([
        axios.get('/api/user/token-historical-data/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/exchange-market-data/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get('/api/user/token-holder-history/', {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);

      const allFailed = results.every((result) => result.status === 'rejected');
      if (allFailed) {
        setHasError(true);
        toast({
          title: 'Error fetching data',
          description: 'Unable to load token data. Please try again later.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        return;
      }

      results.forEach((result, index) => {
        if (result.status === 'fulfilled') {
          switch (index) {
            case 0:
              setVolumeData(result.value.data);
              break;
            case 1:
              setExchangeData(result.value.data);
              break;
            case 2:
              setHolderData(result.value.data);
              break;
          }
        }
      });
    } catch (error) {
      console.error('Error fetching data:', error);
      setHasError(true);
      toast({
        title: 'Error fetching data',
        description: 'Something went wrong while fetching data. Please try again.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTokenData();
  }, []);

  const filteredData = exchangeData.filter((exchange) => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    const nameLower = exchange.exchange.name.toLowerCase();
    const pairLower = exchange.pair.toLowerCase();

    const formattedSearchTerm = searchTermLower.replace(/[\/\s]/g, '');
    const formattedPair = pairLower.replace(/[\/\s]/g, '');

    return (
      nameLower.includes(searchTermLower) ||
      pairLower === searchTermLower ||
      formattedPair.includes(formattedSearchTerm)
    );
  });
  const totalCount = filteredData.length;
  const totalPages = Math.ceil(totalCount / ITEMS_PER_PAGE);
  const hasNext = currentPage < totalPages;
  const hasPrevious = currentPage > 1;

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentData = filteredData.slice(startIndex, endIndex);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const fetchHolders = async (date?: Date) => {
    setIsLoadingHolders(true);
    try {
      let url = '/api/user/address-book/top/';
      if (date) {
        const formattedDate = date.toLocaleDateString('en-CA');
        url += `?date=${formattedDate}`;
      }

      const response = await fetch(url);
      const data = await response.json();
    } catch (error) {
      console.error('Error fetching holders:', error);
    } finally {
      setIsLoadingHolders(false);
    }
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if (date) {
      fetchHolders(date);
    } else {
      fetchHolders();
    }
  };

  const handleExchangeFilterChange = (type: string) => {
    setExchangeType(type);
  };

  const chartConfigs: ChartConfig[] = [
    {
      id: 'price',
      title: 'Daily Price',
      color: 'green',
      useRealData: true,
      tooltip: 'Updated every 24 hours to display the latest token price.',
    },
    {
      id: 'holders',
      title: 'Token Holders',
      color: 'red',
      useRealData: true,
      isSecondChart: true,
      tooltip: 'See how the number of token holders fluctuates over a 7-day period.',
    },
  ];

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  return (
    <Box
      display="flex"
      padding={['12px', '12px', '12px', '24px 24px 40px 24px', '24px 24px 40px 24px']}
      alignItems="flex-start"
      gap={['24px', '24px', '36px', '36px', '36px']}
      alignSelf="stretch"
      flexDirection={'column'}
      background={['none', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'none', 'none']}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap={['12px', '12px', '12px', '16px', '16px']}
        padding={['24px 0px 0px 0px', '24px 0px 0px 0px', '0px']}
        alignSelf="stretch"
        flexShrink={0}
      >
        <Text
          fs={['24px', '24px', '24px', '36px', '36px']}
          lineHeight={['28px', '28px', '28px', '24px', '24px']}
          textAlign={'left'}
        >
          Track Your Token&apos;s Journey
        </Text>
        <Text fs="14px" fw={400} lineHeight={'22px'} textAlign={'left'}>
          Access the latest price data, volume insights, and holder trends updated daily.
        </Text>
      </Box>

      <Box
        display="flex"
        flexDirection={['column', 'column', 'column', 'column', 'column', 'row']}
        alignItems="flex-start"
        gap="14px"
        alignSelf="stretch"
        flexShrink={0}
      >
        <Box
          display="flex"
          flexDirection={['column', 'column', 'row', 'row', 'column', 'column']}
          alignItems="flex-start"
          gap={['10px', '14px', '10px', '14px']}
          flex="1 0 0"
          alignSelf="stretch"
        >
          {chartConfigs.map((config) => (
            <PriceChart
              key={config.id}
              config={config}
              priceData={volumeData}
              holderData={holderData}
              infoTooltip={config.tooltip}
            />
          ))}
        </Box>
        <Box display="flex" alignItems="flex-start" flex={'1 0 0'} gap="14px" alignSelf="stretch">
          <VolumeChart volumeData={volumeData} />
        </Box>
      </Box>

      <Box
        display="flex"
        padding={[' 24px 0px', '24px 0px', '24px 0px', '24px', '24px']}
        flexDirection="column"
        alignItems="center"
        maxHeight={'710px'}
        gap="24px"
        flex="1"
        overflow="hidden"
        alignSelf="stretch"
        borderRadius="36px"
        border={['none', 'none', 'none', '1px solid rgba(255, 255, 255, 0.10)']}
        borderTop={[
          '1px solid rgba(255, 255, 255, 0.10)',
          '1px solid rgba(255, 255, 255, 0.10)',
          '1px solid rgba(255, 255, 255, 0.10)',
          'none',
        ]}
        borderBottom={[
          '1px solid rgba(255, 255, 255, 0.10)',
          '1px solid rgba(255, 255, 255, 0.10)',
          '1px solid rgba(255, 255, 255, 0.10)',
          'none',
        ]}
        background={['transparent', 'transparent', 'transparent', '#FFF']}
        boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
      >
        {/* Table Header */}
        <Box
          display="flex"
          gap={['10px', '10px', '6px']}
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" gap={'6px'} alignItems="center" color="var(--Text-Primary-default, #000)">
            <Text fs="20px" lineHeight={'28px'}>
              Exchange Prices
            </Text>
          </Box>
          <Box
            display="flex"
            width={['fit-content', 'fit-content', '100%', '767.5px']}
            flexDirection={['column', 'column', 'row']}
            justifyContent={['flex-end', 'flex-end', 'space-between', 'flex-end']}
            alignItems={['flex-start', 'flex-start', 'center']}
            gap="12px"
          >
            {/* <DatePicker onDateChange={handleDateChange} selectedDate={selectedDate} validDates={validDates} /> */}
            {/* <ExchangeFilter activeFilter={exchangeType} onFilterChange={handleExchangeFilterChange} /> */}
            <InputGroup
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
            >
              <Image src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                border="none"
                color="#000"
                padding={0}
                value={searchTerm}
                onChange={handleSearch}
                placeholder="Search by Exchange or pairs"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Box>
        </Box>

        {isMobile ? (
          <Box
            display="flex"
            flexDirection="column"
            flex="1"
            width="100%"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                background: '#f1f1f1',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#888',
                borderRadius: '2px',
              },
              '&::-webkit-scrollbar-thumb:hover': {
                background: '#555',
              },
            }}
          >
            {/* Fixed Header */}
            <Box flexShrink={0}>
              <Table variant="unstyled" display={['table', 'table', 'table', 'table']} width={'1198px'}>
                <Thead>
                  <Tr borderRadius="10px" background="#F6F6F6" display="flex" justifyContent="space-between">
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Exchange
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Price
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Pair
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Last Updated
                    </Th>
                  </Tr>
                </Thead>
              </Table>
            </Box>

            {/* Scrollable Table Body */}
            <Box>
              <Table variant="unstyled" display={['table', 'table', 'table', 'table']} width={'1198px'}>
                <Tbody>
                  {currentData.map((exchange) => (
                    <Tr
                      key={exchange.id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom="1px solid var(--Neutrals-4, #E9E9E9)"
                    >
                      <Td
                        display="flex"
                        height="48px"
                        p="0px 12px"
                        alignItems="center"
                        gap="8px"
                        flex="1"
                        onClick={() => window.open('https://coinmarketcap.com/currencies/bitcoin/#Markets', '_blank')}
                      >
                        <Image src={exchange.exchange.logo} alt={exchange.exchange.name} width="24px" height="24px" />
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          fontFamily="VisbyCF"
                          color="var(--Text-Primary-default, #000)"
                        >
                          {exchange.exchange.name}
                        </Text>
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Image src={OrangeDollar} />
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          fontFamily="VisbyCF"
                          color="var(--Text-Primary-default, #000)"
                        >
                          {exchange.price_formatted}
                        </Text>
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Text
                          fs="14px"
                          c="var(--Text-action, #4096FF)"
                          fw={500}
                          lineHeight="22px"
                          cursor="pointer"
                          onClick={() => window.open(exchange.market_pair_url, '_blank')}
                        >
                          {exchange.pair}
                        </Text>
                        <Image
                          src={ExternalLink}
                          cursor="pointer"
                          onClick={() => window.open(exchange.market_pair_url, '_blank')}
                        />
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Image src={RefreshIcon} />
                        <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight="22px">
                          {new Date(exchange.updated_at).toLocaleString()}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" flex="1" width="100%" overflow="hidden">
            {/* Fixed Header */}
            <Box flexShrink={0}>
              <Table variant="unstyled" display={['table', 'table', 'table', 'table']}>
                <Thead>
                  <Tr borderRadius="10px" background="#F6F6F6" display="flex" justifyContent="space-between">
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Exchange
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Price
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Pair
                    </Th>
                    <Th
                      border="none"
                      fontFamily="VisbyCF"
                      color="#1A1A1A"
                      fontSize="13px"
                      fontWeight="600"
                      lineHeight="20px"
                      padding="11px"
                      flex="1"
                    >
                      Last Updated
                    </Th>
                  </Tr>
                </Thead>
              </Table>
            </Box>

            {/* Scrollable Table Body */}
            <Box
              flex="1"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '4px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#f1f1f1',
                  borderRadius: '2px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#888',
                  borderRadius: '2px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: '#555',
                },
              }}
            >
              <Table variant="unstyled" display={['table', 'table', 'table', 'table']}>
                <Tbody>
                  {currentData.map((exchange) => (
                    <Tr
                      key={exchange.id}
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      borderBottom="1px solid var(--Neutrals-4, #E9E9E9)"
                    >
                      <Td
                        display="flex"
                        height="48px"
                        p="0px 12px"
                        alignItems="center"
                        gap="8px"
                        flex="1"
                        onClick={() => window.open('https://coinmarketcap.com/currencies/bitcoin/#Markets', '_blank')}
                      >
                        <Image src={exchange.exchange.logo} alt={exchange.exchange.name} width="24px" height="24px" />
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          fontFamily="VisbyCF"
                          color="var(--Text-Primary-default, #000)"
                        >
                          {exchange.exchange.name}
                        </Text>
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Image src={OrangeDollar} />
                        <Text
                          fontSize="14px"
                          fontWeight="500"
                          lineHeight="22px"
                          fontFamily="VisbyCF"
                          color="var(--Text-Primary-default, #000)"
                        >
                          {exchange.price_formatted}
                        </Text>
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Text
                          fs="14px"
                          c="var(--Text-action, #4096FF)"
                          fw={500}
                          lineHeight="22px"
                          cursor="pointer"
                          onClick={() => window.open(exchange.market_pair_url, '_blank')}
                        >
                          {exchange.pair}
                        </Text>
                        <Image
                          src={ExternalLink}
                          cursor="pointer"
                          onClick={() => window.open(exchange.market_pair_url, '_blank')}
                        />
                      </Td>
                      <Td display="flex" height="48px" p="0px 12px" alignItems="center" gap="8px" flex="1">
                        <Image src={RefreshIcon} />
                        <Text c="var(--Text-Primary-description, #909090)" fs="14px" fw={500} lineHeight="22px">
                          {new Date(exchange.updated_at).toLocaleString()}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </Box>
        )}

        {/* Pagination */}

        <TablePagination
          currentPage={currentPage}
          totalCount={totalCount}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={hasNext}
          hasPrevious={hasPrevious}
        />
      </Box>
    </Box>
  );
}

export default Home;
