import {
  Box,
  Button,
  Flex,
  Image,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import SendReceiveBlock from '../components/common/SendReceiveBlock';
import Text from '../components/common/Text';
import ThreeDots from '../components/common/ThreeDots';
import AddressBookEditModal from '../components/modals/AddressBookEditModal';
import DeleteModal from '../components/modals/DeleteModal';
import { PlusIcon } from '../Icons/PlusIcon';
import { TagIcon } from '../Icons/TagIcon';
import { loadingStore, useLoginStore } from '../store';
import TablePagination from './TablePagination';

interface FlowAddress {
  id: number;
  title: string;
  address: string;
  is_receiver: boolean;
}

const FlowAddressManag = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const setLoading = loadingStore((state: any) => state.setLoading);
  const loading = loadingStore((state: any) => state.loading);
  const setSelectedWalletAddress = useLoginStore((state: any) => state.setSelectedWalletAddress);

  // State
  const [flowAddresses, setFlowAddresses] = useState<FlowAddress[]>([]);
  const [pageIndex, setPageIndex] = useState(() => {
    const savedPage = localStorage.getItem('tokenHoldersPageIndex');
    return savedPage ? parseInt(savedPage, 10) : 1;
  });
  const [pageSize, setPageSize] = useState(() => {
    const savedSize = localStorage.getItem('tokenHoldersPageSize');
    return savedSize ? parseInt(savedSize, 10) : 10;
  });
  const [totalCount, setTotalCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [balanceSort, setBalanceSort] = useState<'none' | 'asc' | 'desc'>('desc');
  const [isShowSuccessModal, setIsShowSuccessModal] = useState(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState(false);
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [selectedHolder, setSelectedHolder] = useState<FlowAddress | null>(null);
  const [deletingRecord, setDeletingRecord] = useState<FlowAddress | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const getFlowAddresses = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/api/user/settings/inflow-outflow-addresses/', {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      if (response.status === 200) {
        const addresses = Object.values(response.data) as FlowAddress[];
        setFlowAddresses(addresses);
        setTotalCount(addresses.length);
      }
    } catch (err) {
      console.error('Error fetching flow addresses:', err);
      toast({
        title: 'Error fetching addresses',
        description: 'Please try again later',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSaveChanges = async (updatedRecord: FlowAddress) => {
    setLoading(true);
    try {
      if (isAddingNew) {
        await axios.post(
          '/api/user/update-inflow-outflow-address-single/',
          {
            title: updatedRecord.title,
            address: updatedRecord.address,
            is_receiver: updatedRecord.is_receiver,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      } else {
        await axios.patch(
          `/api/user/update-inflow-outflow-address-single/${updatedRecord.id}/`,
          {
            title: updatedRecord.title,
            address: updatedRecord.address,
            is_receiver: updatedRecord.is_receiver,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
          },
        );
      }

      await getFlowAddresses();
      setIsShowSuccessModal(false);
      setSelectedHolder(null);
      setIsAddingNew(false);
      toast({
        title: 'Changes saved successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error saving changes:', error);
      toast({
        title: 'Error saving changes',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    if (!deletingRecord) return;
    setLoading(true);
    try {
      await axios.delete(`/api/user/update-inflow-outflow-address-single/${deletingRecord.id}/`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
      });

      await getFlowAddresses();
      setIsShowDeleteModal(false);
      setDeletingRecord(null);
      toast({
        title: 'Record deleted successfully',
        status: 'success',
        isClosable: true,
      });
    } catch (error) {
      console.error('Error deleting record:', error);
      toast({
        title: 'Error deleting record',
        status: 'error',
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const executeSearch = () => {
    setSearchQuery(searchInput);
    setPageIndex(1);
    localStorage.setItem('tokenHoldersPageIndex', '1');
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      executeSearch();
    }
  };

  const handleAdd = () => {
    setSelectedHolder(null);
    setIsAddingNew(true);
    setIsShowSuccessModal(true);
  };

  const handleBalanceSort = () => {
    const nextSort = balanceSort === 'none' ? 'asc' : balanceSort === 'asc' ? 'desc' : 'none';
    setBalanceSort(nextSort);
  };

  const navigateToSinglePage = (address: string) => {
    setSelectedWalletAddress(address);
    navigate(`/addressBooks/${address}`, {
      state: {
        address: address,
        labels: [],
      },
    });
  };

  const handleNameClick = (holder: FlowAddress) => {
    setSelectedHolder(holder);
    setIsAddingNew(false);
    setIsShowSuccessModal(true);
  };

  useEffect(() => {
    getFlowAddresses();
  }, [pageIndex, pageSize, searchQuery]);

  useEffect(() => {
    localStorage.setItem('tokenHoldersPageIndex', pageIndex.toString());
    localStorage.setItem('tokenHoldersPageSize', pageSize.toString());
  }, [pageIndex, pageSize]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    md2: false,
  });

  return (
    <Box
      display="flex"
      padding={['24px 12px', '24px 12px', '24px 12px', '14px 24px 40px 24px']}
      flexDirection="column"
      alignItems="flex-start"
      gap={['24px', '24px', '24px', '56px']}
      flex="1 0 0"
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
      alignSelf="stretch"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text fs={['24px', '24px', '24px', '36px']} lineHeight={'28px'} textAlign={'left'}>
          Flow Address Management
        </Text>
        <Text fs="14px" fw={400} lineHeight={'22px'} textAlign={'left'}>
          Track and configure in-flow and out-flow addresses seamlessly.
        </Text>
      </Box>
      <Box
        display="flex"
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius={['0px', '0px', '0px', '36px']}
        border={['transparent', 'transparent', 'transparent', '1px solid rgba(255, 255, 255, 0.10)']}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        boxShadow={['transparent', 'transparent', 'transparent', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
        background="var(--Neutrals-1, #FFF)"
      >
        {/* Search and Add Section */}
        <Flex
          width="100%"
          justify="space-between"
          align={['flex-end', 'flex-end', 'center']}
          flexDirection={['column', 'column', 'row', 'row']}
          gap={'10px'}
        >
          <InputGroup
            width={['100%', '100%', 'fit-content']}
            padding="0px 20px 0px 12px"
            gap="10px"
            border="0.5px solid var(--Neutrals-4, #E9E9E9)"
            borderRadius="16px"
            background="var(--Neutrals-2, #F6F6F6)"
            alignItems="center"
          >
            <Image src={SearchIcon} alt="Search Icon" />
            <Input
              border="none"
              color="#000"
              padding={0}
              placeholder="Search by address or tag"
              background="transparent"
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              value={searchInput}
              _placeholder={{
                color: 'var(--Text-Primary-description, #909090)',
                fontFamily: 'Poppins',
                fontSize: '14px',
                fontWeight: '500',
              }}
              _focus={{
                border: 'none',
                boxShadow: 'none',
              }}
            />
          </InputGroup>

          <Button
            display="flex"
            padding="6px 16px"
            alignItems="center"
            gap="8px"
            borderRadius="16px"
            border="1px solid #2D8DFB"
            background="#2D8DFB"
            color="#FFF"
            fontFamily="Poppins"
            fontSize="14px"
            fontWeight="500"
            lineHeight="22px"
            onClick={handleAdd}
            _hover={{
              background: '#1E76D3',
              borderColor: '#1E76D3',
            }}
            _active={{
              background: '#145BA1',
              borderColor: '#145BA1',
            }}
          >
            <PlusIcon fill="#FFF" />
            Add New Address
          </Button>
        </Flex>

        {isMobile ? (
          <Box width="100%">
            <Flex flexWrap="wrap" gap={4} flexDirection={['column', 'column', 'row']}>
              {flowAddresses.map((address, index) => (
                <Box
                  key={address.id || index}
                  borderRadius="var(--paddingLG, 24px)"
                  border="1px solid rgba(0, 0, 0, 0.05)"
                  background="var(--Neutrals-1, #FFF)"
                  display="flex"
                  padding="24px"
                  flexDirection="column"
                  alignItems="flex-start"
                  gap="20px"
                  width={['100%', '100%', 'calc(50% - 8px)']}
                  flexGrow={0}
                  flexShrink={0}
                >
                  <Flex justify="space-between" align="center" flexDirection="column" gap={'20px'} width={'100%'}>
                    <Flex align="center" gap={3} width="100%" justify="space-between">
                      <Flex align="center" gap={2}>
                        <Flex
                          display="flex"
                          padding="6px 12px 6px 6px"
                          justifyContent="center"
                          alignItems="center"
                          gap="6px"
                          borderRadius="10px"
                          border="1px solid rgba(0, 0, 0, 0.05)"
                          background="#FFF"
                          onClick={() => handleNameClick(address)}
                        >
                          <TagIcon
                            fill={address.title?.length > 0 ? '#4096FF' : 'var(--Text-Primary-description, #909090)'}
                          />
                          <Text
                            color={
                              address.title?.length > 0
                                ? 'var(--Text-Primary-default, #000)'
                                : 'var(--Text-Primary-description, #909090)'
                            }
                            fontFamily="Poppins"
                            fontSize="14px"
                            fontWeight="500"
                            lineHeight="22px"
                            padding={0}
                          >
                            {address.title?.length > 0 ? address.title : '-'}
                          </Text>
                        </Flex>
                      </Flex>
                      <Box
                        _hover={{
                          color: '#4096FF',
                        }}
                        cursor="pointer"
                        padding={0}
                        color="var(--Text-Primary-description, #909090)"
                      >
                        <ThreeDots
                          holder={address}
                          onEdit={(record) => {
                            setSelectedHolder(record);
                            setIsAddingNew(false);
                            setIsShowSuccessModal(true);
                          }}
                          onDelete={(record) => {
                            setDeletingRecord(record);
                            setIsShowDeleteModal(true);
                          }}
                          onSaveChanges={handleSaveChanges}
                          onConfirmDelete={handleConfirmDelete}
                        />
                      </Box>
                    </Flex>
                    <Flex
                      width="100%"
                      justifyContent={'space-between'}
                      alignItems={['flex-start', 'flex-start', 'flex-start', 'center']}
                      flexDirection={['column', 'column', 'column', 'row']}
                      gap={'10px'}
                    >
                      <Text
                        padding={0}
                        color="var(--Text-action, #4096FF)"
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        textAlign={'left'}
                        cursor="pointer"
                        onClick={() => navigateToSinglePage(address.address)}
                      >
                        {address.address
                          ? `${address.address.slice(0, 8)}...${address.address.slice(-8)}`
                          : 'No Address'}
                      </Text>
                      <SendReceiveBlock type={address.is_receiver ? 'receive' : 'send'} />
                    </Flex>
                  </Flex>
                </Box>
              ))}
            </Flex>
          </Box>
        ) : (
          <Table variant="unstyled">
            <Thead>
              <Tr
                display="flex"
                height="40px"
                padding="0px 12px"
                alignItems="center"
                borderRadius="12px"
                background="var(--Neutrals-2, #F6F6F6)"
              >
                <Th
                  padding={0}
                  flex="1"
                  display="flex"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  justifyContent="space-between"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Title
                  {/* <FilterTag /> */}
                </Th>
                <Th
                  padding={0}
                  flex="1"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Address
                  {/* <BalanceFilter sort={balanceSort} onSort={handleBalanceSort} /> */}
                </Th>
                <Th
                  padding="0px 12px"
                  flex="1"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  alignItems="center"
                  display="flex"
                  justifyContent="space-between"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Status
                </Th>

                <Th
                  padding={0}
                  flex="1"
                  display="flex"
                  justifyContent="flex-end"
                  color="var(--Text-Primary-description, #909090)"
                  fontFamily="Poppins"
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="22px"
                  sx={{
                    textTransform: 'capitalize',
                    '&::first-letter': {
                      fontSize: '14px',
                    },
                  }}
                >
                  Actions
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {flowAddresses.map((address, index) => (
                <Tr
                  key={address.id || index}
                  display="flex"
                  alignItems="center"
                  padding="12px"
                  _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                  borderBottom="1px solid rgba(246, 246, 246, 0.8)"
                >
                  <Td padding={0} flex="1" border="none">
                    <Flex
                      alignItems="center"
                      gap="4px"
                      borderRadius="4px"
                      cursor="pointer"
                      onClick={() => handleNameClick(address)}
                    >
                      <TagIcon
                        fill={address.title?.length > 0 ? '#4096FF' : 'var(--Text-Primary-description, #909090)'}
                      />
                      <Text
                        color={
                          address.title?.length > 0
                            ? 'var(--Text-Primary-default, #000)'
                            : 'var(--Text-Primary-description, #909090)'
                        }
                        fontFamily="Poppins"
                        fontSize="14px"
                        fontWeight="500"
                        lineHeight="22px"
                        padding={0}
                      >
                        {address.title?.length > 0 ? address.title : '-'}
                      </Text>
                    </Flex>
                  </Td>
                  <Td padding={0} flex="1" border="none">
                    <Text
                      padding={0}
                      color="var(--Text-action, #4096FF)"
                      fontFamily="Poppins"
                      fontSize="14px"
                      fontWeight="500"
                      lineHeight="22px"
                      textAlign={'left'}
                      cursor="pointer"
                      onClick={() => navigateToSinglePage(address.address)}
                    >
                      {address.address ? `${address.address.slice(0, 8)}...${address.address.slice(-8)}` : 'No Address'}
                    </Text>
                  </Td>
                  <Td padding={0} flex="1" border="none">
                    <Flex display="flex" alignItems="center" gap="8px">
                      <SendReceiveBlock type={address.is_receiver ? 'receive' : 'send'} />
                    </Flex>
                  </Td>

                  <Td flex="1" border="none" padding={0} display="flex" justifyContent="flex-end">
                    <Box
                      _hover={{
                        color: '#4096FF',
                      }}
                      cursor="pointer"
                      padding={0}
                      color="var(--Text-Primary-description, #909090)"
                    >
                      <ThreeDots
                        holder={address}
                        onEdit={(record) => {
                          setSelectedHolder(record);
                          setIsAddingNew(false);
                          setIsShowSuccessModal(true);
                        }}
                        onDelete={(record) => {
                          setDeletingRecord(record);
                          setIsShowDeleteModal(true);
                        }}
                        onSaveChanges={handleSaveChanges}
                        onConfirmDelete={handleConfirmDelete}
                      />
                    </Box>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        )}

        {/* Pagination */}
        <TablePagination
          currentPage={pageIndex}
          totalCount={totalCount}
          onPageChange={(page) => {
            setPageIndex(page);
            localStorage.setItem('tokenHoldersPageIndex', page.toString());
          }}
          hasNext={pageIndex * pageSize < totalCount}
          hasPrevious={pageIndex > 1}
        />
      </Box>

      {/* Modals */}
      <AddressBookEditModal
        isShowModal={isShowSuccessModal}
        setIsShowModal={setIsShowSuccessModal}
        editingRecord={selectedHolder}
        onSaveChanges={handleSaveChanges}
        isAddingNew={isAddingNew}
        type="flow"
      />

      <DeleteModal
        showModal={isShowDeleteModal}
        isShowModal={setIsShowDeleteModal}
        onConfirmDelete={handleConfirmDelete}
      />

      <Modal
        isOpen={isUpdating}
        isCentered
        closeOnOverlayClick={false}
        closeOnEsc={false}
        onClose={() => setIsUpdating(false)}
      >
        <ModalOverlay />
        <ModalContent bg="white" borderRadius="md" p={6} maxWidth="300px">
          <ModalBody>
            <Flex direction="column" align="center" justify="center" height="100%">
              <Text fontWeight="medium" textAlign="center" mb={4} color="#1f1f1f">
                Updating Address Book
              </Text>
              <Spinner size="xl" color="blue.500" />
              <Text fontSize="sm" textAlign="center" color="#1f1f1f" mt={4}>
                Please wait while we update your address book
              </Text>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FlowAddressManag;
