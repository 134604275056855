import { Text as ChakraText, TextProps as ChakraTextProps, ResponsiveValue } from '@chakra-ui/react';
import { Property } from 'csstype';
import React from 'react';

interface TextProps extends ChakraTextProps {
  c?: string;
  ff?: string;
  fs?: ResponsiveValue<string>;
  fw?: ResponsiveValue<string | number>;
  ta?: ResponsiveValue<Property.TextAlign>;
  children?: React.ReactNode;
}

const Text: React.FC<TextProps> = ({ c, ff, fs, fw, ta, children, ...props }) => {
  const defaultStyles = {
    color: 'var(--Text-Black, #000)',
    textAlign: 'center' as Property.TextAlign,
    fontFamily: 'Poppins',
    fontSize: '20px',
    fontWeight: '600',
  };

  return (
    <ChakraText
      color={c || defaultStyles.color}
      textAlign={ta || defaultStyles.textAlign}
      fontFamily={ff || defaultStyles.fontFamily}
      fontSize={fs || defaultStyles.fontSize}
      fontWeight={fw || defaultStyles.fontWeight}
      {...props}
    >
      {children}
    </ChakraText>
  );
};

export default Text;
