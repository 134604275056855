import { Box, Button, HStack, Input, useColorModeValue, VStack } from '@chakra-ui/react';
import React, { ChangeEvent, ClipboardEvent, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../../api/axios';
import Text from '../common/Text';

export const PassStep2: React.FC = () => {
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const location = useLocation();
  const email = location.state?.email || 'andrehaankwenda@gmail.com';
  const navigate = useNavigate();

  useEffect(() => {
    inputRefs.current = Array(6).fill(null);
  }, []);

  const handleChange = (index: number, value: string): void => {
    if (isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (value !== '' && index < 5 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (index: number, e: KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Backspace' && index > 0 && otp[index] === '' && inputRefs.current[index - 1]) {
      inputRefs.current[index - 1]?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text/plain').slice(0, 6);
    const newOtp = [...otp];

    pastedData.split('').forEach((char: string, index: number) => {
      if (index < 6 && !isNaN(Number(char))) {
        newOtp[index] = char;
      }
    });

    setOtp(newOtp);
  };

  const handleVerifyOTP = async (): Promise<void> => {
    const otpString = otp.join('');
    if (otpString.length !== 6) return;

    setIsLoading(true);
    setError('');

    try {
      const response = await axios.post('/api/user/verify-password-reset-otp/', {
        email,
        otp: otpString,
      });

      if (response.status === 200) {
        navigate('/passstep-3', { state: { email } });
      } else {
        setError(response.data.error || 'Failed to verify OTP');
      }
    } catch (error) {
      setError('Network error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      paddingTop="100px"
      flexDirection="column"
      alignItems="center"
      width="100%"
      bg={useColorModeValue('white', 'gray.800')}
    >
      <VStack spacing={9} width={['100%', '100%', 'fit-content', '410px']} padding={['0px 12px', '0px 24px', '0px']}>
        <VStack spacing={2} alignSelf="stretch" textAlign="center">
          <Text fs={'28px'}>Enter OTP</Text>
          <Text
            fs={'14px'}
            fw={500}
            lineHeight={'22px'}
            c="var(--Text-Primary-description, #909090)"
            textAlign={'center'}
          >
            Enter the 6-digit verification code that was sent to
            <br />
            <span style={{ color: 'var(--Text-action, #4096FF)' }}>{email}</span>
          </Text>
        </VStack>
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" gap="24px">
          <HStack spacing={2} justify="center">
            {otp.map((digit, index) => (
              <Input
                key={index}
                ref={(el) => (inputRefs.current[index] = el)}
                type="text"
                maxLength={1}
                value={digit}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(index, e.target.value)}
                onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
                onPaste={handlePaste}
                borderRadius="8px"
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA)"
                display="flex"
                padding="0px"
                textAlign={'center'}
                alignItems="center"
                gap="10px"
                flex="1 0 0"
                alignSelf="stretch"
                height={'32px'}
                width={'32px'}
              />
            ))}
          </HStack>

          {error && (
            <Text color="red.500" fontSize="14px">
              {error}
            </Text>
          )}

          <Button
            width="100%"
            display="flex"
            padding="6px var(--Button-paddingContentHorizontal, 16px)"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap="8px"
            alignSelf="stretch"
            borderRadius="16px"
            border="1px solid #2D8DFB"
            background="#2D8DFB"
            color="var(--Button-colorTextLightSolid, #FFF)"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="22px"
            _hover={{
              background: '#2D8DFB',
            }}
            onClick={handleVerifyOTP}
            isLoading={isLoading}
            isDisabled={otp.some((digit) => !digit)}
          >
            Reset password
          </Button>
        </Box>
      </VStack>
    </Box>
  );
};

export default PassStep2;
