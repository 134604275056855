import { Box, Flex, Image, Input, InputGroup, Text, useBreakpointValue } from '@chakra-ui/react';
import { format } from 'date-fns';
import { gsap } from 'gsap';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from '../../api/axios';
import CoveyInsightMobileLogo from '../../assets/images/svgs/coveyInsightMobileLogo.svg';
import HamBurgerMenuIconMobile from '../../assets/images/svgs/hamBurgerMenuIconMobile.svg';
import headerCalender from '../../assets/images/svgs/headerCalender.svg';
import Mobilelogo from '../../assets/images/svgs/Mobilelogo.svg';
import profileImage from '../../assets/images/svgs/profileImage.svg';
import SearchIcon from '../../assets/images/svgs/SearchIcon.svg';
import { SidebarProps } from '../../interfaces';
import OverallSearchModal from '../modals/OverallSearchModal';
import NotificationPanel from './Notification';

interface UserData {
  username: string;
}

function Header({ sideBarOpen, setSideBarOpen }: SidebarProps) {
  const location = useLocation();
  const headerRef = useRef<HTMLDivElement>(null);
  const [isTokenHolderPage, setIsTokenHolderPage] = useState(false);
  const { pathname } = location;
  const currentDate = format(new Date(), 'EEEE, dd MMMM');
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [username, setUsername] = useState('Degen');

  useLayoutEffect(() => {
    if (headerRef.current) {
      const ctx = gsap.context(() => {
        gsap.from('.gsap-item-left', {
          x: -50,
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
        });

        gsap.from('.gsap-item-right', {
          x: 50,
          opacity: 0,
          duration: 0.5,
          ease: 'power2.out',
        });
      }, headerRef);

      return () => ctx.revert();
    }
  }, []);

  useEffect(() => {
    setIsTokenHolderPage(pathname === '/tokenHolders');
  }, [pathname]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get<{ data: UserData }>('api/user/current/');
        setUsername(response.data.data.username);
      } catch (error) {
        console.error('Error fetching username:', error);
      }
    };

    fetchUserData();
  }, []);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: true,
    md2: false,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const sidebar = document.querySelector('.mobile-sidebar');
      const hamburgerButton = document.querySelector('.hamburger-button');

      if (
        sideBarOpen &&
        sidebar &&
        hamburgerButton &&
        !sidebar.contains(event.target as Node) &&
        !hamburgerButton.contains(event.target as Node)
      ) {
        setSideBarOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [sideBarOpen, setSideBarOpen]);

  return (
    <>
      {isMobile ? (
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap="10px"
          borderBottom="0.5px solid #E9E9E9"
          background="#FFF"
          padding={'12px'}
          boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.12)"
          position="relative"
          zIndex={2}
        >
          <Box display={'flex'} alignItems={'flex-start'} justifyContent={'space-between'}>
            <Image src={Mobilelogo} />
            <NotificationPanel />
          </Box>
          <Box display="flex" justifyContent={'space-between'} gap={'10px'}>
            <Flex width="fit-content" justify="space-between" align="center" flex={1}>
              <InputGroup
                cursor="pointer"
                flex={1}
                width="fit-content"
                padding="0px 20px 0px 12px"
                gap="10px"
                border="0.5px solid var(--Neutrals-4, #E9E9E9)"
                borderRadius="16px"
                background="var(--Neutrals-2, #F6F6F6)"
                alignItems="center"
                onClick={() => setIsSearchModalOpen(true)}
              >
                <Image onClick={() => setIsSearchModalOpen(true)} src={SearchIcon} alt="search" cursor="pointer" />
                <Input
                  readOnly
                  onClick={(e) => {
                    e.preventDefault();
                    setIsSearchModalOpen(true);
                  }}
                  border="none"
                  color="#000"
                  padding={0}
                  placeholder="Search by address or tag"
                  background="transparent"
                  _placeholder={{
                    color: 'var(--Text-Primary-description, #909090)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                  _focus={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </InputGroup>
            </Flex>
            <Box
              className="hamburger-button"
              onClick={() => setSideBarOpen(!sideBarOpen)}
              style={{
                display: 'flex',
                padding: '10px 12px',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '16px',
                background: '#F6F6F6',
                cursor: 'pointer',
              }}
            >
              <Image src={HamBurgerMenuIconMobile} />
            </Box>
          </Box>

          {/* Mobile Sidebar Overlay */}
          {sideBarOpen && (
            <Box
              position="fixed"
              top="0"
              left="0"
              right="0"
              bottom="0"
              bg="rgba(0, 0, 0, 0.5)"
              zIndex={1}
              onClick={() => setSideBarOpen(false)}
            />
          )}
        </Box>
      ) : (
        <Box
          padding="12px 24px"
          minW={['unset', 'unset', 'unset', 'unset', '1070px']}
          w="100%"
          borderBottom="1px solid rgba(242, 243, 245, 0.14)"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          ref={headerRef}
          zIndex={1}
          className="content-header"
          background="#FFF"
          boxShadow="0px 1px 6px 0px rgba(0, 0, 0, 0.12)"
        >
          <Box
            className="gsap-item-left"
            display="flex"
            alignItems="center"
            gap={['8px', '16px', '16px', '16px', '16px']}
          >
            <Box
              h={['30px', '44px', '44px', '44px', '44px']}
              w={['30px', '44px', '44px', '44px', '44px']}
              borderRadius="50%"
              backgroundColor="#FFFFFF"
              display={['flex', 'flex', 'none', 'none', 'none']}
              justifyContent="center"
              alignItems="center"
              onClick={() => setSideBarOpen(!sideBarOpen)}
              cursor="pointer"
            >
              <Image
                h={['22px', '22px', '44px', '44px', '44px']}
                w={['22px', '22px', '44px', '44px', '44px']}
                src={HamBurgerMenuIconMobile}
                alt="logo"
              />
            </Box>
            <Image
              h={['64px', '64px', '0', '0', '0']}
              w={['64px', '64px', '0', '0', '0']}
              src={CoveyInsightMobileLogo}
              alt="logo"
            />
            <Box
              display="flex"
              padding="10px"
              alignItems="center"
              gap="10px"
              borderRadius="100px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Image
                h={['0', '0', '44px', '24px', '24px']}
                w={['0', '0', '44px', '24px', '24px']}
                src={headerCalender}
                alt="logo"
              />
            </Box>
            <Text
              color="var(--Text-Primary-default, #000)"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="16px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              {currentDate}
            </Text>
          </Box>

          <Flex width="fit-content" justify="space-between" align="center">
            <InputGroup
              cursor="pointer"
              width="fit-content"
              padding="0px 20px 0px 12px"
              gap="10px"
              border="0.5px solid var(--Neutrals-4, #E9E9E9)"
              borderRadius="16px"
              background="var(--Neutrals-2, #F6F6F6)"
              alignItems="center"
              onClick={() => setIsSearchModalOpen(true)}
            >
              <Image onClick={() => setIsSearchModalOpen(true)} src={SearchIcon} alt="search" cursor="pointer" />
              <Input
                readOnly
                onClick={(e) => {
                  e.preventDefault();
                  setIsSearchModalOpen(true);
                }}
                border="none"
                color="#000"
                padding={0}
                placeholder="Search by address or tag"
                background="transparent"
                _placeholder={{
                  color: 'var(--Text-Primary-description, #909090)',
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontWeight: '500',
                }}
                _focus={{
                  border: 'none',
                  boxShadow: 'none',
                }}
              />
            </InputGroup>
          </Flex>

          <Box
            className="gsap-item-right"
            display="flex"
            alignItems="center"
            gap={['8px', '16px', '16px', '12px', '12px']}
          >
            <NotificationPanel />
            <Flex
              display={['none', 'none', 'block', 'flex', 'flex']}
              borderLeft="1px solid rgba(0, 0, 0, 0.05)"
              fontSize="16px"
              fontStyle="normal"
              width="fit-content"
              fontWeight="500px"
              color="#909090"
              flexDirection="row"
              height="100%"
            >
              <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingLeft: '12px' }}>
                <Image src={profileImage} width="44px" height="44px" />
                <Text
                  color="var(--Text-Primary-default, #000)"
                  fontFamily="Poppins"
                  fontSize="18px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="38px"
                >
                  {username}
                </Text>
              </div>
            </Flex>
          </Box>
        </Box>
      )}
      {isSearchModalOpen && <OverallSearchModal onClose={() => setIsSearchModalOpen(false)} />}
    </>
  );
}

export default Header;
