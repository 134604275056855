import { Box, Flex, FormControl, FormLabel, Input, Text, useBreakpointValue, useToast } from '@chakra-ui/react';
import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import axios from '../../api/axios';
import Cross from '../../assets/images/svgs/cross.svg';

import AppModal from './AppModal';

interface IProps {
  isShowModal: boolean;
  setIsShowModal: (e: boolean) => void;
  onSuccess: () => void;
  initialTokenAddress?: string;
}

const TokenAddressModal: React.FC<IProps> = ({ isShowModal, setIsShowModal, onSuccess, initialTokenAddress = '' }) => {
  const [tokenAddress, setTokenAddress] = useState(initialTokenAddress);
  const toast = useToast();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  useEffect(() => {
    if (isShowModal) {
      setTokenAddress(initialTokenAddress || '');
    }
  }, [initialTokenAddress, isShowModal]);

  const handleSaveChanges = async () => {
    try {
      const response = await axios.patch(
        '/api/user/patch-token-address/',
        { token_address: tokenAddress },
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('access_token')}` },
        },
      );

      if (response.status === 200) {
        toast({
          title: 'Success',
          description: 'Token address updated successfully',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
        onSuccess();
        setIsShowModal(false);
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Failed to update token address',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      {isMobile ? (
        <AppModal
          open={isShowModal}
          onCancel={() => setIsShowModal(false)}
          onClose={() => setIsShowModal(false)}
          width={['100%', '100%', '100%', '390px']}
          style={{ top: 10 }}
          styles={{
            content: {
              display: 'flex',
              width: '100%',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '36px',
              border: '1px solid rgba(255, 255, 255, 0.10)',
              background: 'var(--Neutrals-1, #FFF)',
              boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.08)',
            },
            body: { width: '100%', height: '100%', gap: '12px' },
          }}
        >
          <Flex width="100%" paddingLeft="24px" justifyContent="center" alignItems="center">
            <Text
              color="var(--Text-Black, #000)"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight={600}
              lineHeight="28px"
              flex={1}
            >
              Provide token address
            </Text>
            <Box cursor="pointer" display="flex" alignItems="center" onClick={() => setIsShowModal(false)}>
              <img src={Cross} alt="Close" />
            </Box>
          </Flex>

          <Box width="100%" display="flex" flexDirection="column" gap="16px">
            <FormControl>
              <Text
                color="var(--Neutrals-500, #8A8A8A)"
                fontSize="12px"
                fontStyle="normal"
                fontWeight={400}
                lineHeight="16px"
              >
                Token Address
              </Text>

              <Input
                placeholder="Enter token address"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                height="48px"
                width="100%"
                padding="8px 13px"
                alignItems="center"
                gap="10px"
                color="black"
                fontFamily={'Poppins'}
                alignSelf="stretch"
                borderRadius="14px"
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA)"
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
              />
            </FormControl>
          </Box>

          <Button
            onClick={handleSaveChanges}
            style={{
              display: 'flex',
              padding: '6px var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              border: '1px solid #2D8DFB',
              background: '#2D8DFB',
              color: 'var(--Button-colorTextLightSolid, #FFF)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              height: '48px',
              fontWeight: 500,
              lineHeight: '22px',
            }}
          >
            Save
          </Button>
        </AppModal>
      ) : (
        <AppModal
          open={isShowModal}
          onCancel={() => setIsShowModal(false)}
          onClose={() => setIsShowModal(false)}
          width={'fit-content'}
          style={{ top: 10 }}
          styles={{
            content: {
              display: 'flex',
              width: '390px',
              padding: '24px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '36px',
              border: '1px solid rgba(255, 255, 255, 0.10)',
              background: 'var(--Neutrals-1, #FFF)',
              boxShadow: '0px 1px 7px 0px rgba(0, 0, 0, 0.08)',
            },
            body: { width: '100%', height: '100%', gap: '12px' },
          }}
        >
          <Flex width="100%" paddingLeft="24px" justifyContent="center" alignItems="center">
            <Text
              color="var(--Text-Black, #000)"
              textAlign="center"
              fontFamily="Poppins"
              fontSize="18px"
              fontStyle="normal"
              fontWeight={600}
              lineHeight="28px"
              flex={1}
            >
              Provide token address
            </Text>
            <Box cursor="pointer" display="flex" alignItems="center" onClick={() => setIsShowModal(false)}>
              <img src={Cross} alt="Close" />
            </Box>
          </Flex>

          <Box width="100%" display="flex" flexDirection="column" gap="16px">
            <FormControl>
              <FormLabel
                color="var(--Neutrals-500, #8A8A8A)"
                fontFamily="Poppins"
                fontSize="12px"
                fontStyle="normal"
                fontWeight={400}
                lineHeight="16px"
              >
                Token Address
              </FormLabel>
              <Input
                placeholder="Enter token address"
                value={tokenAddress}
                onChange={(e) => setTokenAddress(e.target.value)}
                height="48px"
                width="100%"
                padding="8px 13px"
                alignItems="center"
                gap="10px"
                color="black"
                alignSelf="stretch"
                borderRadius="14px"
                fontFamily={'Poppins'}
                border="0.5px solid var(--Neutrals-200, #DEDEDE)"
                background="var(--Neutrals-100, #FAFAFA)"
                _placeholder={{
                  color: 'var(--Neutrals-300, #C2C2C2)',
                  fontFamily: 'Poppins',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '20px',
                }}
              />
            </FormControl>
          </Box>

          <Button
            onClick={handleSaveChanges}
            style={{
              display: 'flex',
              padding: '6px var(--Button-paddingContentHorizontal, 16px)',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              alignSelf: 'stretch',
              borderRadius: '16px',
              border: '1px solid #2D8DFB',
              background: '#2D8DFB',
              color: 'var(--Button-colorTextLightSolid, #FFF)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              height: '48px',
              lineHeight: '22px',
            }}
          >
            Save
          </Button>
        </AppModal>
      )}
    </>
  );
};

export default TokenAddressModal;
