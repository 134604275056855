import {
  Box,
  Flex,
  Image,
  Input,
  InputGroup,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';
import axios from '../api/axios';
import SearchIcon from '../assets/images/svgs/SearchIcon.svg';
import AmountTab from '../components/common/AmountTab';
import FetchTx from '../components/common/FetchTx';
import SendReceiveBlock from '../components/common/SendReceiveBlock';
import Text from '../components/common/Text';
import { BalanceIcon } from '../Icons/BalanceIcon';
import TablePagination from '../pages/TablePagination';
import { useLoginStore } from '../store';
import { Transaction, TransactionResponse } from '../types/Types';
import { getExplorerUrl, openInNewTab } from '../utils/walletUtils/walletutils';

const AllTransactions = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [transactionData, setTransactionData] = useState<TransactionResponse | null>(null);
  const [loading, setLoading] = useState(true);
  const ITEMS_PER_PAGE = 10;

  const chainId = useLoginStore((state: any) => state.userData.blockchain_network.chain_id);

  useEffect(() => {
    console.log('chainId updated:', chainId);
  }, [chainId]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        const response = await axios.get<TransactionResponse>(`/api/user/user-transactions/?page=${currentPage}`);
        setTransactionData(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  }, [currentPage]);

  const amountData = useMemo(
    () => [
      {
        title: 'Total Sent',
        amount: transactionData?.stats?.total_sent
          ? Number(transactionData.stats.total_sent).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : '0',
        percentage: transactionData?.stats?.sent_24h_change || '0',
        isPositive: Number(transactionData?.stats?.sent_24h_change || '0') >= 0,
        showReceiveBlock: false,
      },
      {
        title: 'Total Received',
        amount: transactionData?.stats?.total_received
          ? Number(transactionData.stats.total_received).toLocaleString(undefined, { maximumFractionDigits: 2 })
          : '0',
        percentage: transactionData?.stats?.received_24h_change || '0',
        isPositive: Number(transactionData?.stats?.received_24h_change || '0') >= 0,
        showReceiveBlock: false,
      },
      {
        title: 'Largest Transaction',
        amount: transactionData?.stats?.largest_transaction?.amount
          ? Number(transactionData.stats.largest_transaction.amount).toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })
          : '0',
        percentage: transactionData?.stats?.largest_transaction?.change_24h || '0',
        isPositive: Number(transactionData?.stats?.largest_transaction?.change_24h || '0') >= 0,
        showReceiveBlock: true,
      },
    ],
    [transactionData],
  );

  const filteredData = useMemo(() => {
    if (!transactionData?.results) return { data: [], totalCount: 0 };

    let filtered = transactionData.results;
    if (searchTerm) {
      filtered = filtered.filter(
        (tx) =>
          tx.sender.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.receiver.toLowerCase().includes(searchTerm.toLowerCase()) ||
          tx.transaction_hash.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }

    return {
      data: filtered,
      totalCount: transactionData.count,
    };
  }, [transactionData, searchTerm]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
    window.scrollTo(0, 0);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  if (loading) {
    return <Box>Loading...</Box>;
  }

  const handleFetchWithDates = (dateRange: [Date | null, Date | null]) => {
    const fetchTransactions = async () => {
      try {
        setLoading(true);
        let url = `/api/user/user-transactions/?page=${currentPage}`;

        if (dateRange[0] && dateRange[1]) {
          const startDate = dateRange[0].toISOString().split('T')[0];
          const endDate = dateRange[1].toISOString().split('T')[0];
          url += `&start_date=${startDate}&end_date=${endDate}`;
        }

        const response = await axios.get<TransactionResponse>(url);
        setTransactionData(response.data);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTransactions();
  };

  return (
    <Box
      display="flex"
      padding={['24px 12px', '24px 12px', '24px 12px', '14px 24px 40px 24px']}
      flexDirection="column"
      alignItems="center"
      gap={['24px', '24px', '24px', '36px']}
      flex="1 0 0"
      background={['var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'var(--Neutrals-1, #FFF)', 'transparent']}
      alignSelf="stretch"
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        alignItems="flex-start"
        gap="16px"
        alignSelf="stretch"
      >
        <Text
          c="var(--Text-Primary-default, #000)"
          fs={['24px', '24px', '24px', '36px']}
          lineHeight="28px"
          textAlign={'left'}
        >
          Transaction History
        </Text>
        <Text c="var(--Text-Primary-default, #000)" fs="14px" fw={400} lineHeight="22px">
          Track all your wallet&apos;s activity in one place.
        </Text>
      </Box>

      <Box
        display="flex"
        alignItems="flex-start"
        gap="14px"
        alignSelf="stretch"
        flexDirection={['column', 'column', 'column', 'row']}
      >
        {amountData.map((item, index) => (
          <AmountTab
            key={index}
            title={item.title}
            amount={item.amount}
            percentage={item.percentage}
            isPositive={item.isPositive}
            showReceiveBlock={item.showReceiveBlock}
          />
        ))}
      </Box>

      <Box
        display="flex"
        padding={['24px 0px', '24px 0px', '24px 0px', '24px']}
        flexDirection="column"
        alignItems="center"
        gap="24px"
        alignSelf="stretch"
        borderRadius={['0px', '0px', '0px', '36px']}
        border="1px solid rgba(255, 255, 255, 0.10)"
        background={['transparent', 'transparent', 'transparent', 'var(--Neutrals-1, #FFF)']}
        borderTop={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        borderBottom={[
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          '1px solid rgba(0, 0, 0, 0.05)',
          'none',
        ]}
        boxShadow={['none', 'none', 'none', '0px 1px 7px 0px rgba(0, 0, 0, 0.08)']}
      >
        {isMobile ? (
          <Flex flexDirection={'column'} width={'100%'} gap={'12px'}>
            <Flex
              justifyContent="space-between"
              width="100%"
              flex="1 0 0"
              flexDirection={['column', 'column', 'row']}
              alignItems={['flex-start', 'flex-start', 'center']}
              gap={'8px'}
            >
              <Text fs="20px" lineHeight="28px" textAlign={'left'}>
                Transactions
              </Text>
              <Flex justifyContent={'flex-end'}>
                <FetchTx onFetch={handleFetchWithDates} />
              </Flex>
            </Flex>

            <Flex gap="12px" justify="space-between" align="center" width={'100%'}>
              <InputGroup
                flex={1}
                width="fit-content"
                padding="0px 20px 0px 12px"
                gap="10px"
                border="0.5px solid var(--Neutrals-4, #E9E9E9)"
                borderRadius="16px"
                background="var(--Neutrals-2, #F6F6F6)"
                alignItems="center"
              >
                <Image src={SearchIcon} alt="search" cursor="pointer" />
                <Input
                  border="none"
                  color="#000"
                  padding={0}
                  placeholder="Search by Address or Tx Hash"
                  background="transparent"
                  value={searchTerm}
                  onChange={handleSearch}
                  _placeholder={{
                    color: 'var(--Text-Primary-description, #909090)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                  _focus={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        ) : (
          <Flex justifyContent="space-between" width="100%" flex="1 0 0" alignItems="center">
            <Text fs="20px" lineHeight="28px">
              Transactions
            </Text>

            <FetchTx onFetch={handleFetchWithDates} />

            <Flex gap="12px" justify="space-between" align="center">
              <InputGroup
                width="fit-content"
                padding="0px 20px 0px 12px"
                gap="10px"
                border="0.5px solid var(--Neutrals-4, #E9E9E9)"
                borderRadius="16px"
                background="var(--Neutrals-2, #F6F6F6)"
                alignItems="center"
              >
                <Image src={SearchIcon} alt="search" cursor="pointer" />
                <Input
                  border="none"
                  color="#000"
                  padding={0}
                  placeholder="Search by Address or Tx Hash"
                  background="transparent"
                  value={searchTerm}
                  onChange={handleSearch}
                  _placeholder={{
                    color: 'var(--Text-Primary-description, #909090)',
                    fontFamily: 'Poppins',
                    fontSize: '14px',
                    fontWeight: '500',
                  }}
                  _focus={{
                    border: 'none',
                    boxShadow: 'none',
                  }}
                />
              </InputGroup>
            </Flex>
          </Flex>
        )}

        <Table
          variant="unstyled"
          alignItems={'flex-start'}
          display={'flex'}
          flexDirection={'column'}
          overflowX={'auto'}
        >
          <Thead width={'100%'}>
            <Tr
              display="flex"
              width={['1228px', '1228px', '1228px', '1228px', '100%']}
              height="40px"
              padding="0px 12px"
              alignItems="center"
              borderRadius="12px"
              background="var(--Neutrals-2, #F6F6F6)"
            >
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Amount
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Sender
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Receiver
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tx Hash
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                lineHeight="22px"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Tx Type
              </Th>
              <Th
                padding={0}
                flex="1"
                color="var(--Text-Primary-description, #909090)"
                fontFamily="Poppins"
                fontSize="14px"
                fontWeight="500"
                textAlign="right"
                lineHeight="22px"
                justifyContent="flex-end"
                sx={{
                  textTransform: 'capitalize',
                  '&::first-letter': {
                    fontSize: '14px',
                  },
                }}
              >
                Block Time
              </Th>
            </Tr>
          </Thead>

          <Tbody width={'100%'}>
            {filteredData.data.map((transaction: Transaction) => (
              <Tr
                key={transaction.id}
                display="flex"
                width={['1228px', '1228px', '1228px', '1228px', '100%']}
                alignItems="center"
                padding="12px"
                _hover={{ background: 'var(--Neutrals-1, #FFF)' }}
                borderBottom="1px solid rgba(246, 246, 246, 0.8)"
              >
                <Td padding="0px" flex="1" border="none" display="flex" gap="8px" paddingRight="12px">
                  <BalanceIcon fill={transaction.transfer_type === 'Receive' ? '#39CA7F' : '#FF4D4F'} />
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {Number(transaction.amount).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {`${transaction.sender.slice(0, 6)}...${transaction.sender.slice(-3)}`}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0} textAlign="left">
                    {`${transaction.receiver.slice(0, 6)}...${transaction.receiver.slice(-3)}`}
                  </Text>
                </Td>
                <Td
                  padding="0px"
                  paddingRight="12px"
                  flex="1"
                  border="none"
                  onClick={() => openInNewTab(getExplorerUrl(Number(chainId), transaction.transaction_hash))}
                  cursor={'pointer'}
                >
                  <Text
                    c="var(--Text-action, #4096FF)"
                    fs="14px"
                    fw={500}
                    lineHeight="22px"
                    padding={0}
                    textAlign="left"
                  >
                    {`${transaction.transaction_hash.slice(0, 6)}...${transaction.transaction_hash.slice(-4)}`}
                  </Text>
                </Td>
                <Td padding="0px" paddingRight="12px" flex="1" border="none">
                  <Flex
                    alignItems="center"
                    gap="4px"
                    borderRadius="4px"
                    cursor="pointer"
                    onClick={() => openInNewTab(getExplorerUrl(Number(chainId), transaction.transaction_hash))}
                  >
                    <SendReceiveBlock type={transaction.transfer_type.toLowerCase() as 'send' | 'receive'} />
                  </Flex>
                </Td>
                <Td flex="1" border="none" padding="0px" paddingRight="12px" display="flex" justifyContent="flex-end">
                  <Text fs="14px" fw={500} lineHeight="22px" padding={0}>
                    {new Date(transaction.block_time).toLocaleString('en-US', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: true,
                    })}
                  </Text>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>

        <TablePagination
          currentPage={currentPage}
          totalCount={transactionData?.count || 0}
          entriesPerPage={ITEMS_PER_PAGE}
          onPageChange={handlePageChange}
          hasNext={!!transactionData?.next}
          hasPrevious={!!transactionData?.previous}
        />
      </Box>
    </Box>
  );
};

export default AllTransactions;
