import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import TokenDashboardWrapper from './components/common/TokenDashBoardWrapper';

import Overlay from './components/common/Overlay';
import PassStep1 from './components/PasswordReset/PassStep1';
import PassStep2 from './components/PasswordReset/PassStep2';
import PassStep3 from './components/PasswordReset/PassStep3';
import PassStep4 from './components/PasswordReset/PassStep4';
import Step1 from './components/signup/Step1';
import Step2 from './components/signup/Step2';
import Step3 from './components/signup/Step3';
import WalletInfo from './components/tokenHolder/walletInfo/WalletInfo';

import AddressBook from './pages/AddressBook';
import AllTransactions from './pages/AllTransactions';
import FlowAddressManag from './pages/FlowAddressManag';
import Login from './pages/Login';
import Settings from './pages/Settings';

import BookDashboard from './pages/BookDashboard';
import SankeyChart from './pages/SankeyChart';
import SignupMain from './pages/SignupMain';
import TokenHolders from './pages/TokenHolders';
import TxInfo from './pages/TxInfo';
import { useLoginStore } from './store';
import { CustomTheme } from './theme/theme';

function AuthenticatedRoutes() {
  return (
    <ChakraProvider theme={CustomTheme}>
      <BrowserRouter>
        <Overlay>
          <Routes>
            {/* Main Routes */}
            <Route path="/" element={<TokenDashboardWrapper />} />
            <Route path="/tokenHolders" element={<TokenHolders />} />
            <Route path="/BookDashboard" element={<BookDashboard />} />
            <Route path="/transactions" element={<AllTransactions />} />
            <Route path="/reports" element={<TxInfo />} />
            <Route path="/SankeyChart" element={<SankeyChart />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/addressBook" element={<AddressBook />} />

            {/* Unified Wallet Details Routes */}
            <Route path="/tokenHolders/:id" element={<WalletInfo />} />
            <Route path="/addressBooks/:id" element={<WalletInfo />} />
            <Route path="/demo/:id" element={<WalletInfo />} />
            <Route path="/transactions/:id" element={<WalletInfo />} />
            <Route path="/holder/:address" element={<WalletInfo />} />
            <Route path="/search/:address" element={<WalletInfo />} />
            <Route path="/flowAddress" element={<FlowAddressManag />} />
          </Routes>
        </Overlay>
      </BrowserRouter>
    </ChakraProvider>
  );
}

function UnAuthenticatedRoutes() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/sign-up" element={<SignupMain />} />
          <Route path="/step-1" element={<Step1 />} />
          <Route path="/step-2" element={<Step2 />} />
          <Route path="/step-3" element={<Step3 />} />
          <Route path="/passstep-1" element={<PassStep1 />} />
          <Route path="/passstep-2" element={<PassStep2 />} />
          <Route path="/passstep-3" element={<PassStep3 />} />
          <Route path="/passstep-4" element={<PassStep4 />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

const App = () => {
  const userData = useLoginStore((state: any) => state.userData);
  const isLoggedIn = Object.keys(userData).length > 0 ? true : false;

  if (isLoggedIn) {
    return <AuthenticatedRoutes />;
  }
  return <UnAuthenticatedRoutes />;
};

export default App;
